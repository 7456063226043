import React, { useEffect, useState, Fragment } from 'react';
import { Prompt } from 'react-router-dom';
import { Button, Box, Paper, Tabs, Tab } from '@material-ui/core';
import { routeHistory } from '../../../routing/history';
import SendIcon from '@material-ui/icons/Send';
import HistoryIcon from '@material-ui/icons/History';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommitForm, setCommitFormOpen } from '../jsonSlice';
import downloadString from '../download/downloadString';
import GetAppIcon from '@material-ui/icons/GetApp';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import PublishIcon from '@material-ui/icons/Publish';
import CommitForm from '../commit/CommitForm';
import JSONEditorReact from '../JSONEditor';
import DiffDialog from '../DiffDialog';
import CommandForm from './CommandForm.tsx';
import LanguageForm from './LanguageForm.tsx';
import { firebaseInactiveProjectId } from '../../..';

export default function BaseJSON({
  classes,
  disabled,
  data,
  text,
  handleInput,
  loaded,
  mode,
  modes,
  handleValidation,
  onChangeText,
  onModeChange,
  json,
}) {
  const [diffOpen, setDiffOpen] = useState(false);
  const dispatch = useDispatch();
  const commitFormOpen = useSelector(selectCommitForm);
  const [value, setValue] = useState(0);
  const loc = window.location.href;
  const [baseScenario, setBaseScenario] = useState(false);
  const [baseGroupScenario, setBaseGroupScenario] = useState(false);
  const [commandDM, setCommandDM] = useState(false);
  const [commandGroup, setCommandGroup] = useState(false);
  const [languageKeys, setLanguageKeys] = useState(false);
  const notCommand = !commandDM && !commandGroup;
  const prodInactive =
    firebaseInactiveProjectId === 'tumla-prod' ? true : false;

  useEffect(() => {
    setBaseGroupScenario(loc.includes('base-scenario/group'));
    setCommandDM(loc.includes('base-scenario/command-dm'));
    setCommandGroup(loc.includes('base-scenario/command-group'));
    setLanguageKeys(loc.includes('base-scenario/language-keys'));
    setBaseScenario(
      !baseGroupScenario && !commandDM && !commandGroup && !languageKeys,
    );
  }, [loc, baseGroupScenario, commandDM, commandGroup, languageKeys]);

  const handleChange = (event, newValue) => {
    console.log(event);
    setValue(newValue);
    switch (newValue) {
      case 0:
        routeHistory.push('/base-scenario');
        break;
      case 1:
        routeHistory.push('/base-scenario/group');
        break;
      case 2:
        routeHistory.push('/base-scenario/command-dm');
        break;
      case 3:
        routeHistory.push('/base-scenario/command-group');
        break;
      case 4:
        routeHistory.push('/base-scenario/language-keys');
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <Button
          onClick={() => routeHistory.push('/remote-config/inactive-diff/base-scenario')}
          variant="contained"
          color="secondary"
          style={{ width: '110px' }}
        >
          {prodInactive ? 'Prod' : 'Dev'} Diff
        </Button>
        <Paper style={{ width: '100%' }} className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Base Scenario" disabled={baseScenario} />
            <Tab label="Group Scenario" disabled={baseGroupScenario} />
            <Tab label="Command DM" disabled={commandDM} />
            <Tab label="Command Group" disabled={commandGroup} />
            <Tab label="Language Keys" disabled={languageKeys} />
          </Tabs>
        </Paper>
      </div>
      {notCommand && !languageKeys && (
        <Fragment>
          <Prompt
            when={!disabled}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          {!commitFormOpen && (
            <div>
              <Box className={classes.root}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => dispatch(setCommitFormOpen())}
                  startIcon={<SendIcon />}
                  disabled={disabled}
                >
                  Commit changes
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => routeHistory.push('/base-history')}
                  startIcon={<HistoryIcon />}
                >
                  History
                </Button>
                <Button
                  variant="contained"
                  className={classes.download}
                  onClick={() => downloadString(text, data.title)}
                  startIcon={<GetAppIcon />}
                >
                  Download
                </Button>
                <Button
                  className={classes.upload}
                  variant="contained"
                  component="label"
                  startIcon={<PublishIcon />}
                >
                  Upload
                  <input type="file" hidden onChange={handleInput} />
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => setDiffOpen(true)}
                  startIcon={<CompareArrowsIcon />}
                  disabled={disabled}
                >
                  Diff
                </Button>
              </Box>
            </div>
          )}
          {commitFormOpen && (
            <CommitForm
              data={data}
              newText={text}
              loaded={loaded}
              base={true}
            />
          )}
          {notCommand && (
            <Fragment>
              <JSONEditorReact
                onValidationError={(e) => handleValidation(e)}
                text={text}
                mode={mode}
                modes={modes}
                indentation={4}
                onChangeText={onChangeText}
                onModeChange={onModeChange}
              />
              {diffOpen && (
                <DiffDialog
                  open={diffOpen}
                  setDialogOpen={setDiffOpen}
                  oldText={JSON.stringify(json, null, 2)}
                  newText={JSON.stringify(JSON.parse(text), null, 2)}
                  previous={false}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}

      {!notCommand && !languageKeys && (
        <CommandForm
          json={json}
          data={data}
          loaded={loaded}
          classes={classes}
        />
      )}

      {languageKeys && (
        <LanguageForm json={json} classes={classes} data={data} />
      )}
    </Fragment>
  );
}
