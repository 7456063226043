import React from "react";
import { ListItemIcon, Divider, List, ListItemText } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import HomeIcon from '@material-ui/icons/Home';
import { Link } from "react-router-dom";
import BaseScenario from "../../pages/BaseScenario";
import BotManagerPage from "../../pages/BotManagerPage";
import HomePage from "../../pages/HomePage/HomePage";
import AdbIcon from '@material-ui/icons/Adb';
import SettingsIcon from '@material-ui/icons/Settings';

const data = {
  menus: [
    { text: "Home", icon: <HomeIcon />, link: "/", component: { HomePage } },
    { text: "Approvals", icon: <SettingsIcon />, link: "/base-scenario", component: { BaseScenario } },
    { text: "Bots", icon: <AdbIcon />, link: "/bot-manager", component: { BotManagerPage } },
  ]
}


export default function AppDrawerMenu() {
  return (
    <div>
      <Divider />
      <List>
        {data.menus.map((menu, index) => (
          <ListItem button key={menu.text} to={menu.link} component={Link}>
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );
}
