import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommitForm from '../commit/CommitForm';
import {
  selectCommitForm,
  setCommitFormClose,
  setCommitFormOpen,
} from '../jsonSlice';
import JSONEditorReact from '../JSONEditor';
import SendIcon from '@material-ui/icons/Send';
import {
  Button,
  makeStyles,
  createStyles,
  Box,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';
import '../JSONEditorApp/JSONEditorApp.css';
import GetAppIcon from '@material-ui/icons/GetApp';
import downloadString from '../download/downloadString';
import {
  getRemoteConfigChatBot,
  selectBaseJson,
  selectBotData,
  selectPreviousBotData,
  selectRemoteChatBot,
  setBaseJson,
} from '../../../features/bots/botSlice';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import DiffDialog from '../DiffDialog';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: '#2196f3',
      color: 'white',
    },
    name: {
      backgroundColor: '#E5E2E2',
      color: 'black',
    },
    download: {
      backgroundColor: '#59AD23',
      color: 'white',
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

export default function JSONBaseViewer({
  json,
  data,
  parentId = null,
  groupJson = false,
}) {
  const text = JSON.stringify(json, null, 2);
  const [diffCurrent, setDiffCurrent] = useState(false);
  const [diffPrevious, setDiffPrevious] = useState(false);
  const dispatch = useDispatch();
  const commitFormOpen = useSelector(selectCommitForm);
  const classes = useStyles();
  const baseJson = useSelector(selectBaseJson);
  const remoteChatBot = useSelector(selectRemoteChatBot);
  const currentBotData = useSelector(selectBotData);
  const botData = currentBotData[0];
  const [currentText, setCurrentText] = useState();
  const [previousText, setPreviousText] = useState();
  const [equalCurrent, setEqualCurrent] = useState(false);
  const [equalPrevious, setEqualPrevious] = useState(false);
  const loc = window.location.pathname;
  const languageHist = loc.includes('language');

  const previousBotData = useSelector(selectPreviousBotData);
  const previousBot = previousBotData && previousBotData[0];

  const commandDM = 'commandDM';
  const commandGroup = 'commandGroup';
  const language = 'language';
  const baseScenario = 'baseScenario';
  const baseGroupScenario = 'baseGroupScenario';
  const commandLang = [commandDM, commandGroup, language];
  const mapObj = commandLang.includes(baseJson) || languageHist;
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        dispatch(setBaseJson(baseScenario));
        break;
      case 1:
        dispatch(setBaseJson(baseGroupScenario));
        break;
      case 2:
        dispatch(setBaseJson(commandDM));
        break;
      case 3:
        dispatch(setBaseJson(commandGroup));
        break;
      case 4:
        dispatch(setBaseJson(language));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    switch (baseJson) {
      case baseScenario:
        setCurrentText(remoteChatBot[baseScenario]);
        setPreviousText(previousBot && previousBot[baseScenario]);
        break;
      case baseGroupScenario:
        setCurrentText(remoteChatBot[baseGroupScenario]);
        setPreviousText(previousBot && previousBot[baseGroupScenario]);
        break;
      case commandDM:
        setCurrentText(remoteChatBot[commandDM]);
        setPreviousText(previousBot && previousBot[commandDM]);
        break;
      case commandGroup:
        setCurrentText(remoteChatBot[commandGroup]);
        setPreviousText(previousBot && previousBot[commandGroup]);
        break;
      case language:
        setCurrentText(remoteChatBot[language]);
        setPreviousText(previousBot && previousBot[language]);
        break;
      default:
        break;
    }
  }, [baseJson, previousBot, remoteChatBot]);

  useEffect(() => {
    dispatch(setCommitFormClose());
    dispatch(getRemoteConfigChatBot());
  }, [dispatch]);

  useEffect(() => {
    let myCurrentText;
    let myPreviousText;
    if (
      !mapObj &&
      typeof currentText === 'string' &&
      typeof previousText === 'string'
    ) {
      myCurrentText =
        currentText && JSON.stringify(JSON.parse(currentText), null, 2);
      myPreviousText =
        previousText && JSON.stringify(JSON.parse(previousText), null, 2);
    } else {
      myCurrentText = currentText && JSON.stringify(currentText);
      myPreviousText = previousText && JSON.stringify(previousText);
    }
    setEqualCurrent(
      myCurrentText === JSON.stringify(JSON.parse(text), null, 2),
    );
    setEqualPrevious(
      myPreviousText === JSON.stringify(JSON.parse(text), null, 2),
    );
  }, [text, mapObj, currentText, previousText]);

  useEffect(() => {
    console.log(previousBot);
  }, [previousBot]);

  return (
    <div className="app">
      <div className="contents">
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Base Scenario" disabled={baseJson === baseScenario} />
            <Tab
              label="Group Scenario"
              disabled={baseJson === baseGroupScenario}
            />
            {botData[commandDM] && (
              <Tab label="Command DM" disabled={baseJson === commandDM} />
            )}
            {botData[commandGroup] && (
              <Tab label="Command Group" disabled={baseJson === commandGroup} />
            )}
            {botData[language] && (
              <Tab label="Language Keys" disabled={baseJson === language} />
            )}
          </Tabs>
        </Paper>
        {!commitFormOpen && (
          <div>
            <Box className={classes.root}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => dispatch(setCommitFormOpen())}
                startIcon={<SendIcon />}
                disabled={equalCurrent}
              >
                Back to this version
              </Button>
              <Button
                variant="contained"
                className={classes.download}
                onClick={() => downloadString(text, data.title, true)}
                startIcon={<GetAppIcon />}
              >
                Download
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => setDiffCurrent(true)}
                startIcon={<CompareArrowsIcon />}
                disabled={equalCurrent}
              >
                Diff Current
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => setDiffPrevious(true)}
                startIcon={<CompareArrowsIcon />}
                disabled={equalPrevious || !previousBot}
              >
                {previousBot ? 'Diff Previous' : 'Previous Not Available'}
              </Button>
            </Box>
          </div>
        )}
        {commitFormOpen && (
          <CommitForm
            newText={mapObj ? JSON.parse(text) : text}
            data={data}
            parentId={parentId}
            group={groupJson}
            base={true}
            language={languageHist}
          />
        )}
        <JSONEditorReact text={text} mode={'view'} indentation={4} />
        {diffCurrent && (
          <DiffDialog
            open={diffCurrent}
            setDialogOpen={setDiffCurrent}
            oldText={
              mapObj
                ? currentText && JSON.stringify(currentText, null, 2)
                : JSON.stringify(JSON.parse(currentText), null, 2)
            }
            newText={JSON.stringify(JSON.parse(text), null, 2)}
            previous={false}
          />
        )}
        {diffPrevious && (
          <DiffDialog
            open={diffPrevious}
            setDialogOpen={setDiffPrevious}
            oldText={
              mapObj
                ? previousText && JSON.stringify(previousText, null, 2)
                : JSON.stringify(JSON.parse(previousText), null, 2)
            }
            newText={JSON.stringify(JSON.parse(text), null, 2)}
            previous={true}
          />
        )}
      </div>
    </div>
  );
}
