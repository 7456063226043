import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { routes } from './routing/routes';
import { routeHistory } from './routing/history';
import firebase from 'firebase/app';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

const firebaseDevConfig = {
  apiKey: 'AIzaSyBv5Y9lSlrnZ70nr1W7Pkr0Neczj2EkGG4',
  authDomain: 'tumla2020.firebaseapp.com',
  databaseURL: 'https://tumla2020.firebaseio.com',
  projectId: 'tumla2020',
  storageBucket: 'tumla2020.appspot.com',
  messagingSenderId: '272818466053',
  appId: '1:272818466053:web:a3ea09a85b9f86523e1b6c',
  measurementId: 'G-7ENYR5FRX3',
};
const firebaseProdConfig = {
  apiKey: 'AIzaSyCTl1ywVxzQOSdIcmNzLVTCDNqeGeGo4Zw',
  authDomain: 'tumla-prod.firebaseapp.com',
  databaseURL: 'https://tumla-prod.firebaseio.com',
  projectId: 'tumla-prod',
  storageBucket: 'tumla-prod.appspot.com',
  messagingSenderId: '558264199257',
  appId: '1:558264199257:web:f5973b450807ff0f342948',
  measurementId: 'G-ZXFZZK6HTD',
};

const firebaseConfig = process.env.REACT_APP_STAGE === 'production' ? firebaseProdConfig : firebaseDevConfig;

const firebaseInactiveEnv = firebaseConfig === firebaseDevConfig ? firebaseProdConfig : firebaseDevConfig;
export const firebaseInactiveProjectId = firebaseInactiveEnv.projectId;

firebase.initializeApp(firebaseConfig);
export let firebaseInactive = firebase.initializeApp(firebaseInactiveEnv, 'inactive');

ReactDOM.render(
  <Provider store={store}>
    <Router history={routeHistory}>{routes}</Router>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

class AxiosNestService {
  currentUserToken$: Observable<String | null>;
  private axiosInstance: AxiosInstance = axios.create({
    baseURL: `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/api/`,
  });

  constructor() {
    const onAuthStateChanged$ = Observable.create((obs) => {
      return firebase.auth().onAuthStateChanged(
        (user) => obs.next(user),
        (err) => obs.error(err),
        () => obs.complete(),
      );
    });

    this.currentUserToken$ = onAuthStateChanged$.pipe(
      switchMap((user) => {
        if (user) {
          return (user as any).getIdToken();
        } else {
          return of(null);
        }
      }),
    );
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        // Do something before request is sent
        const userToken = await this.currentUserToken$.pipe(take(1)).toPromise();
        config.headers = { ...config.headers, Authorization: `Bearer ${userToken}` };
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );
  }

  get = this.axiosInstance.get;
  post = this.axiosInstance.post;
  put = this.axiosInstance.put;
  delete = this.axiosInstance.delete;

  postObservable(url: string, data: any, config?: AxiosRequestConfig | undefined): Observable<any> {
    return Observable.create((observer: any) => {
      this.axiosInstance
        .post(url, data, config)
        .then((response) => {
          observer.next(response.data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
}

export const axiosInstance = new AxiosNestService();
