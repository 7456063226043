import React from 'react';
import '../DrawerContent.css';
import './HomePage.css';
import robot from '../images/robot.png';
import tumla from '../images/tumla_logo.png';

export default function HomePage() {
  return (
    <div className="drawerContent">
      <div id="main">
        <div id="tumla">
          <img id="tumlaLogo" alt="tumla" src={tumla} />
          <h2>Tumla Bot Manager</h2>
        </div>
        <img id="robot" alt="robot" src={robot} />
      </div>
    </div>
  );
}
