import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import JSONBaseViewer from '../app/json-editor/JSONViewerApp/JSONBaseViewer';
import {
  getSpecificBaseHistoryData,
  getSpecificBasePreviousData,
  selectBaseJson,
  selectBotData,
} from '../features/bots/botSlice';
import './DrawerContent.css';

export default function SpecificBaseHistory() {
  const dispatch = useDispatch();
  const currentBotData = useSelector(selectBotData);
  const botData = currentBotData[0];
  const baseJson: string = useSelector(selectBaseJson);
  let { editId } = useParams<{ editId: string }>();
  const commandLang = ['commandDM', 'commandGroup', 'language'];
  const mapObj = commandLang.includes(baseJson);

  let { previousId } = useParams<{ previousId: string }>();

  useEffect(() => {
    dispatch(getSpecificBaseHistoryData(editId));
  }, [dispatch, editId]);

  useEffect(() => {
    dispatch(getSpecificBasePreviousData(previousId));
  }, [dispatch, previousId]);

  return (
    <div>
      {botData && (
        <div className="drawerContent">
          <JSONBaseViewer
            json={mapObj ? botData[baseJson] : JSON.parse(botData[baseJson])}
            data={baseJson}
          />
        </div>
      )}
    </div>
  );
}
