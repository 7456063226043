import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import JSONBotViewer from '../app/json-editor/JSONViewerApp/JSONBotViewer';
import {
  clearBotData,
  getSpecificHistoryData,
  getSpecificPreviousData,
  selectBotData,
} from '../features/bots/botSlice';
import './DrawerContent.css';

export default function SpecificHistory() {
  const dispatch = useDispatch();
  const currentBotData = useSelector(selectBotData);
  const botData = currentBotData[0];

  const loc = window.location.pathname;
  const group = loc.includes('group-json');
  const language = loc.includes('language');

  let { editId } = useParams<{ editId: string }>();
  let { botId } = useParams<{ botId: string }>();
  let { previousId } = useParams<{ previousId: string }>();

  const blocks = botData?.scenario ? false : true;

  function historyToView() {
    if (group) {
      if (botData?.groupScenario) {
        return 'groupScenario';
      } else {
        return 'groupScenarioBlocks';
      }
    } else {
      if (botData?.scenario) {
        return 'scenario';
      } else {
        return 'scenarioBlocks';
      }
    }
  }

  useEffect(() => {
    dispatch(clearBotData());
    dispatch(getSpecificHistoryData(botId, editId));
  }, [editId, botId, dispatch]);

  useEffect(() => {
    dispatch(getSpecificPreviousData(botId, previousId));
  }, [botId, dispatch, previousId]);

  useEffect(() => {
    console.log(botData)
  })

  return (
    <div>
      <div className="drawerContent">
        {botData && (
          <JSONBotViewer
            json={
              language
                ? botData.language || {}
                : JSON.parse(botData?.[historyToView()])
            }
            data={botData}
            parentId={botId}
            blocks={blocks}
            groupJson={group}
          />
        )}
      </div>
    </div>
  );
}
