import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import firebase from 'firebase/app';


interface ClientDataState {

    clientDataList?: Array<ClientData>;
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

interface ClientDataPayload {
    clientDataList?: ClientData[];
}

const initialState: ClientDataState = {
    loading: 'idle',
};

export const userApprovalSlice = createSlice({
    name: 'userApproval',
    initialState,
    reducers: {
        successfulClientData: (state, action: PayloadAction<ClientDataPayload>) => {
            state.clientDataList = action.payload as Array<ClientData>;
            state.loading = 'succeeded';
        }
    },
});

export const { successfulClientData } = userApprovalSlice.actions;

export const getClientData = (clientDataPayload: ClientDataPayload): AppThunk => dispatch => {
    var arrayResult = [];
    var resultStore = firebase.firestore();
    resultStore.collection("CB_client_data").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            arrayResult.push(doc.data() as ClientData);
        });
        setTimeout(() => {
            dispatch(successfulClientData(arrayResult));
        }, 300);
    })
};



export const selectClientData = (state: RootState) => state.userApproval.clientDataList;
export const selectLoadingStatus = (state: RootState) => state.userApproval.loading;


export default userApprovalSlice.reducer;