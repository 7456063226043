import React, { useEffect, useState } from 'react';
import './DrawerContent.css';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInactiveBaseJson,
  getRemoteConfigChatBot,
  selectRemoteChatBot,
  selectRemoteInactiveChatBot,
} from '../features/bots/botSlice';
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';
import { firebaseInactiveProjectId } from '..';
import {
  selectCommitForm,
  setCommitFormOpen,
} from '../app/json-editor/jsonSlice';
import CommitFormInactive from '../app/json-editor/commit/CommitFormInactive';
import './InactiveDiff.css';
import { useParams } from 'react-router-dom';
import { routeHistory } from '../routing/history';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: '#2196f3',
      color: 'white',
    },
    name: {
      backgroundColor: '#E5E2E2',
      color: 'black',
    },
    download: {
      backgroundColor: '#59AD23',
      color: 'white',
    },
    upload: {
      backgroundColor: '#EE6811',
      color: 'white',
    },
    clear: {
      backgroundColor: '#F80606',
      color: 'white',
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);
export default function InactiveDiff() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  // const [baseScenario, setBaseScenario] = useState(true);
  // const [baseGroupScenario, setBaseGroupScenario] = useState(false);
  // const [commandDM, setCommandDM] = useState(false);
  // const [commandGroup, setCommandGroup] = useState(false);
  // const [language, setLanguage] = useState(false);
  const [oldText, setOldText] = useState<any>();
  const [newText, setNewText] = useState<any>();
  const dispatch = useDispatch();
  const remoteChatBot = useSelector(selectRemoteChatBot);
  const remoteInactiveChatBot = useSelector(selectRemoteInactiveChatBot);
  const prodInactive =
    firebaseInactiveProjectId === 'tumla-prod' ? true : false;
  const commitFormOpen = useSelector(selectCommitForm);

  let { field } = useParams<{ field: string }>();
  const baseScenario = field === 'base-scenario';
  const baseGroupScenario = field === 'base-group-scenario';
  const commandDM = field === 'command-dm';
  const commandGroup = field === 'command-group';
  const language = field === 'language';

  const handleChange = (event: any, newValue: number) => {
    console.log(event);
    setValue(newValue);
    switch (newValue) {
      case 0:
        setActive('base-scenario');
        break;
      case 1:
        setActive('base-group-scenario');
        break;
      case 2:
        setActive('command-dm');
        break;
      case 3:
        setActive('command-group');
        break;
      case 4:
        setActive('language');
        break;
      default:
        break;
    }
  };

  function setActive(active: string) {
    routeHistory.push(`/remote-config/inactive-diff/${active}`);
  }

  useEffect(() => {
    if (baseScenario) {
      setOldText(
        remoteChatBot.baseScenario
          ? JSON.parse(remoteChatBot.baseScenario)
          : {},
      );
      setNewText(
        remoteInactiveChatBot.baseScenario
          ? JSON.parse(remoteInactiveChatBot.baseScenario)
          : {},
      );
    } else if (baseGroupScenario) {
      setOldText(
        remoteChatBot.baseGroupScenario
          ? JSON.parse(remoteChatBot.baseGroupScenario)
          : {},
      );
      setNewText(
        remoteInactiveChatBot.baseGroupScenario
          ? JSON.parse(remoteInactiveChatBot.baseGroupScenario)
          : {},
      );
    } else if (commandDM) {
      setOldText(remoteChatBot.commandDM ? remoteChatBot.commandDM : {});
      setNewText(
        remoteInactiveChatBot.commandDM ? remoteInactiveChatBot.commandDM : {},
      );
    } else if (commandGroup) {
      setOldText(remoteChatBot.commandGroup ? remoteChatBot.commandGroup : {});
      setNewText(
        remoteInactiveChatBot.commandGroup
          ? remoteInactiveChatBot.commandGroup
          : {},
      );
    } else if (language) {
      setOldText(remoteChatBot.language ? remoteChatBot.language : {});
      setNewText(
        remoteInactiveChatBot.language ? remoteInactiveChatBot.language : {},
      );
    }
  }, [
    baseScenario,
    baseGroupScenario,
    commandDM,
    commandGroup,
    language,
    remoteChatBot,
    remoteInactiveChatBot,
  ]);

  function sortDoubleObj(unsorted: Object) {
    const sorted = sortObject(unsorted);
    for (let key in sorted) {
      sorted[key] = sortObject(sorted[key]);
    }
    return sorted;
  }

  function sortObject(unsorted: Object) {
    const sorted = Object.keys(unsorted)
      .sort()
      .reduce((obj, key) => {
        obj[key] = unsorted[key];
        return obj;
      }, {});
    return sorted;
  }

  useEffect(() => {
    dispatch(getRemoteConfigChatBot());
    dispatch(getInactiveBaseJson());
  }, [dispatch]);

  return (
    <div className="drawerContent">
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Base Scenario" disabled={baseScenario} />
          <Tab label="Group Scenario" disabled={baseGroupScenario} />
          <Tab label="Command DM" disabled={commandDM} />
          <Tab label="Command Group" disabled={commandGroup} />
          <Tab label="Language Keys" disabled={language} />
        </Tabs>
      </Paper>
      {commitFormOpen && (
        <CommitFormInactive
          activeBaseJson={remoteChatBot}
          inactiveJson={remoteInactiveChatBot}
        />
      )}
      {!commitFormOpen && (
        <Button
          onClick={() => dispatch(setCommitFormOpen())}
          variant="contained"
          className={classes.button}
        >
          Push {prodInactive ? 'Prod' : 'Dev'} to{' '}
          {prodInactive ? 'Dev' : 'Prod'}
        </Button>
      )}
      {!(remoteChatBot.baseScenario && remoteInactiveChatBot.baseScenario) && (
        <CircularProgress
          color="secondary"
          style={{ margin: 'auto', marginTop: '100px' }}
        />
      )}
      {remoteChatBot.baseScenario && remoteInactiveChatBot.baseScenario && (
        <ReactDiffViewer
          oldValue={JSON.stringify(sortDoubleObj(oldText) || {}, null, 2)}
          newValue={JSON.stringify(sortDoubleObj(newText) || {}, null, 2)}
          compareMethod={DiffMethod.WORDS}
          splitView={true}
        />
      )}
    </div>
  );
}
