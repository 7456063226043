let FileSaver = require("file-saver");

export default function downloadJson(text, fileName, history=false) {
   let blob = new Blob([text], { type: "text/plain;charset=utf-8" });
   if (history) {
      FileSaver.saveAs(blob, `${fileName} HISTORY.txt`);
   } else {
      FileSaver.saveAs(blob, `${fileName}.txt`);
   }
}
