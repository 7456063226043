import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import JSONEditorApp from '../app/json-editor/JSONEditorApp/JSONEditorApp';
import {
  clearBotData,
  getSpecificBotData,
  selectBotData,
} from '../features/bots/botSlice';
import './DrawerContent.css';

export default function SpecificBot() {
  const dispatch = useDispatch();
  const currentBotData = useSelector(selectBotData);
  const botData = currentBotData[0];
  const [jsonToParse, setJsonToParse] = useState<string>();
  const [isJson, setIsJson] = useState<boolean>(false);
  const [noJson, setNoJson] = useState<boolean>(true);

  let { botId } = useParams<{ botId: string }>();

  useEffect(() => {
    dispatch(clearBotData());
    dispatch(getSpecificBotData(botId));
  }, [dispatch, botId]);

  useEffect(() => {
    setNoJson(!botData?.scenario && !botData?.scenarioBlocks);
    setJsonToParse(
      botData?.scenario ? botData.scenario : botData?.scenarioBlocks,
    );
    try {
      JSON.parse(jsonToParse);
      setIsJson(true);
    } catch (_) {
      setIsJson(false);
    }
  }, [botData, jsonToParse]);

  return (
    <div className="drawerContent">
      {jsonToParse && isJson && (
        <JSONEditorApp
          json={JSON.parse(jsonToParse || null)}
          data={botData}
          base={false}
          loaded={botData?.scenario ? 'scenario' : 'scenarioBlocks'}
        />
      )}
      {noJson && (
        <JSONEditorApp
          json={{}}
          data={botData}
          base={false}
          loaded={'scenario'}
        />
      )}
      {!noJson && !isJson && (
        <Typography
          style={{ textAlign: 'center', color: '#cc3300' }}
          variant="h3"
        >
          Sorry, invalid data, not a JSON.
        </Typography>
      )}
    </div>
  );
}
