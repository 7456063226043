import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Button,
  makeStyles,
  createStyles,
  LinearProgress,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import SendIcon from '@material-ui/icons/Send';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import { setCommitFormClose } from '../jsonSlice';
import { useDispatch, useSelector } from 'react-redux';
import './CommitForm.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getRemoteConfigChatBot,
  getSpecificBotData,
  selectRemoteChatBot,
} from '../../../features/bots/botSlice';
import { routeHistory } from '../../../routing/history';
import { axiosInstance } from '../../..';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: '#2196f3',
      color: 'white',
    },
  }),
);

interface Values {
  message: string;
}

const initialValues: Values = {
  message: '',
};

toast.configure();

export default function CommitForm({
  data,
  newText,
  parentId = null,
  loaded = null,
  group = false,
  language = false,
  base,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const remoteChatBot = useSelector(selectRemoteChatBot);

  const baseScenario = data === 'baseScenario';
  const baseGroupScenario = data === 'baseGroupScenario';
  const commandDM = data === 'commandDM';
  const commandGroup = data === 'commandGroup';
  const languageKeys = data === 'languageKeys';

  const scenario = loaded === 'scenario';

  const loc = window.location.href;
  const baseHistory = loc.includes('base-history');

  function toUpdate() {
    if (language) {
      return 'language';
    }
    if (group) {
      if (data.groupScenario) {
        return 'groupScenario';
      } else {
        return 'groupScenarioBlocks';
      }
    } else {
      if (scenario) {
        return 'scenario';
      } else {
        return 'scenarioBlocks';
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        setDisabled(true);
        axiosInstance.post('upsertChatBot', 
          base
            ? {
                id: 'chat-bot',
                baseScenario: baseScenario
                  ? newText
                  : remoteChatBot.baseScenario,
                baseGroupScenario: baseGroupScenario
                  ? newText
                  : remoteChatBot.baseGroupScenario,
                commandGroup: commandGroup
                  ? newText
                  : remoteChatBot.commandGroup,
                commandDM: commandDM ? newText : remoteChatBot.commandDM,
                language: languageKeys ? newText : remoteChatBot.language,
                message: values.message,
              }
            : {
                chatGroupCategoryIds: data.chatGroupCategoryIds,
                created: data.created,
                defaultSchoolId: data.defaultSchoolId,
                defaultSettings: data.defaultSettings,
                defaultUserGroupId: data.defaultUserGroupId,
                description: data.description,
                groupScenario:
                  toUpdate() === 'groupScenario' ? newText : data.groupScenario,
                groupScenarioBlocks:
                  toUpdate() === 'groupScenarioBlocks'
                    ? newText
                    : data.groupScenarioBlocks,
                id: parentId || data.id,
                links: data.links,
                message: values.message,
                name: data.name,
                language: toUpdate() === 'language' ? newText : data.language,
                scenario: toUpdate() === 'scenario' ? newText : data.scenario,
                scenarioBlocks:
                  toUpdate() === 'scenarioBlocks'
                    ? newText
                    : data.scenarioBlocks,
                telegramGroupIds: data.telegramGroupIds,
                timezone: data.timezone,
                title: data.title,
                token: data.token,
                updated: data.updated,
              },
        )
          .then(function (result) {
            dispatch(setCommitFormClose());
            console.log('DATA SENT!', result.data);
            if (base) {
              if (baseHistory) {
                routeHistory.push('/base-scenario');
                toast.success('🚀 DATA SENT AND JSON REVERTED!', {
                  autoClose: false,
                });
              } else {
                dispatch(getRemoteConfigChatBot());
                toast.success('🚀 DATA SENT!');
              }
            } else {
              if (parentId) {
                routeHistory.push(
                  group
                    ? `/bot-manager/${parentId}/group-json`
                    : `/bot-manager/${parentId}`,
                );
                toast.success('🚀 DATA SENT AND JSON REVERTED!', {
                  autoClose: false,
                });
              } else {
                dispatch(getSpecificBotData(data.id));
                toast.success('🚀 DATA SENT!');
              }
            }
          })
          .catch(function (error) {
            var code = error.code;
            var message = error.message;
            var details = error.details;
            dispatch(setCommitFormClose());
            toast.error('🙄 SORRY, THERE WAS A PROBLEM!');
            console.error(
              'SORRY, THERE WAS A PROBLEM!',
              code,
              message,
              details,
            );
          });
        resetForm();
      }}
    >
      {({ submitForm }) => (
        <Form>
          <Box margin={1}>
            <Field
              component={TextField}
              name="message"
              type="text"
              label="Message"
            />
            <Button
              variant="contained"
              className={classes.button}
              disabled={disabled}
              onClick={submitForm}
              startIcon={<SendIcon />}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(setCommitFormClose())}
              startIcon={<CancelScheduleSendIcon />}
              disabled={disabled}
            >
              Cancel
            </Button>
          </Box>
          {disabled && <LinearProgress />}
        </Form>
      )}
    </Formik>
  );
}
