import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import {
   selectLoadingAuth,
   selectUser,
} from "../features/authentication/authenticationSlice";
import { selectGeneralRemoteConfig } from "../features/remoteconfig/remoteConfigSlice";

export default function PrivateRoute({ component: Component, ...rest }) {
   const user = useSelector(selectUser);
   const authLoading = useSelector(selectLoadingAuth);
   const generalRemoteConfig = useSelector(selectGeneralRemoteConfig);
   const authorizedUids = generalRemoteConfig.superAdminUserIds;

   return (
      <div>
         {authLoading === "succeeded" && (
            <Route
               {...rest}
               render={(props) =>
                  user && authorizedUids.includes(user.id) ? (
                     <Component {...props} />
                  ) : (
                     <Redirect
                        to={{
                           pathname: "/login",
                           state: { from: props.location },
                        }}
                     />
                  )
               }
            />
         )}
         {authLoading === "failed" && (
            <Route
               {...rest}
               render={(props) => (
                  <Redirect
                     to={{
                        pathname: "/login",
                        state: { from: props.location },
                     }}
                  />
               )}
            />
         )}
      </div>
   );
}
