import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import { routeHistory } from '../../../routing/history';
import MuiDialog from '../Dialog';
import SendIcon from '@material-ui/icons/Send';
import HistoryIcon from '@material-ui/icons/History';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommitForm, setCommitFormOpen } from '../jsonSlice';
import downloadString from '../download/downloadString';
import GetAppIcon from '@material-ui/icons/GetApp';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import PublishIcon from '@material-ui/icons/Publish';
import CommitForm from '../commit/CommitForm';
import JSONEditorReact from '../JSONEditor';
import DiffDialog from '../DiffDialog';
import LanguageIcon from '@material-ui/icons/Language';

export default function NotBaseJSON({
  classes,
  disabled,
  data,
  text,
  handleInput,
  loaded,
  mode,
  modes,
  handleValidation,
  onChangeText,
  onModeChange,
  json,
}) {
  const [ diffOpen, setDiffOpen ] = useState(false);
  const dispatch = useDispatch();
  const commitFormOpen = useSelector(selectCommitForm);
  const [ dialogOpen, setDialogOpen ] = useState(false);

  return (
    <>
      <Prompt when={!disabled} message="You have unsaved changes, are you sure you want to leave?" />
      {!commitFormOpen && (
        <div>
          <Box className={classes.root}>
            <Button variant="contained" className={classes.name} onClick={() => setDialogOpen(true)}>
              @{data?.name}
            </Button>
            {dialogOpen && <MuiDialog setDialogOpen={setDialogOpen} open={dialogOpen} data={data} />}
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => dispatch(setCommitFormOpen())}
              startIcon={<SendIcon />}
              disabled={disabled}
            >
              Commit changes
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => routeHistory.push(`/bot-manager/${data.id}/edits-history`)}
              startIcon={<HistoryIcon />}
            >
              History
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => routeHistory.push(`/bot-manager/${data.id}/group-json`)}
              startIcon={<GroupWorkIcon />}
            >
              Group JSON
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => routeHistory.push(`/bot-manager/${data.id}/language`)}
              startIcon={<LanguageIcon />}
            >
              Language
            </Button>
            <Button
              variant="contained"
              className={classes.download}
              onClick={() => downloadString(text, data.title)}
              startIcon={<GetAppIcon />}
            >
              Download
            </Button>
            <Button className={classes.upload} variant="contained" component="label" startIcon={<PublishIcon />}>
              Upload
              <input type="file" hidden onChange={handleInput} />
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setDiffOpen(true)}
              startIcon={<CompareArrowsIcon />}
              disabled={disabled}
            >
              Diff
            </Button>
          </Box>
        </div>
      )}

      {commitFormOpen && <CommitForm data={data} newText={text} loaded={loaded} base={false} />}
      <JSONEditorReact
        onValidationError={(e) => handleValidation(e)}
        text={text}
        mode={mode}
        modes={modes}
        indentation={4}
        onChangeText={onChangeText}
        onModeChange={onModeChange}
      />
      {diffOpen && (
        <DiffDialog
          open={diffOpen}
          setDialogOpen={setDiffOpen}
          oldText={JSON.stringify(json, null, 2)}
          newText={JSON.stringify(JSON.parse(text), null, 2)}
          previous={false}
        />
      )}
    </>
  );
}
