import { configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authenticationReducer from '../features/authentication/authenticationSlice';
import localStorageReducer from '../features/localstorage/localStorageSlice';
import userApprovalReducer from '../features/approval/userApprovalSlice';
import botReducer from '../features/bots/botSlice';
import remoteConfigReducer from '../features/remoteconfig/remoteConfigSlice';
import jsonReducer from  './json-editor/jsonSlice';



import thunkMiddleware from "redux-thunk";
import { createLogger } from 'redux-logger';


const rootReducer = combineReducers(
  {
    counter: counterReducer,
    authentication: authenticationReducer,
    localStorage: localStorageReducer,
    userApproval: userApprovalReducer,
    botReducer: botReducer,
    remoteConfigReducer: remoteConfigReducer,
    jsonReducer: jsonReducer,
  }
);

const loggerMiddleware = createLogger();
const middleware = [...getDefaultMiddleware(), thunkMiddleware, loggerMiddleware]
export const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;