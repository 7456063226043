import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Field, FieldArray, Form, Formik } from 'formik';
import { object, string, array } from 'yup';
import { TextField } from 'formik-material-ui';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import '../app/json-editor/JSONEditorApp/CommandForm.css';
// import './CommandForm.css';
import _ from 'lodash';
import CommitForm from '../app/json-editor/commit/CommitForm';
import {
  selectCommitForm,
  setCommitFormOpen,
} from '../app/json-editor/jsonSlice';
import { useDispatch, useSelector } from 'react-redux';
import HistoryIcon from '@material-ui/icons/History';
import { routeHistory } from '../routing/history';
import DiffDialog from '../app/json-editor/DiffDialog';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { Prompt, useParams } from 'react-router-dom';
import {
  clearBotData,
  getSpecificBotData,
  selectBotData,
} from '../features/bots/botSlice';
import './DrawerContent.css';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: '#2196f3',
      color: 'white',
    },
  }),
);

var isEqual = function (initialValues: Object[], values: Object[]) {
  if (initialValues.length === values.length) {
    return _(initialValues).differenceWith(values, _.isEqual).isEmpty();
  } else {
    return false;
  }
};

const emptyLanguageKey = { name: '', en: '' };

export default function SpecificLanguage() {
  const [diffOpen, setDiffOpen] = useState<boolean>(false);
  const [commitValues, setCommitValues] = useState<Object>();
  const [oldText, setOldText] = useState<string>();
  const [newText, setNewText] = useState<string>();
  const dispatch = useDispatch();
  const commitFormOpen = useSelector(selectCommitForm);
  const classes = useStyles();

  const currentBotData = useSelector(selectBotData);
  const botData = currentBotData[0];
  let { botId } = useParams<{ botId: string }>();

  useEffect(() => {
    dispatch(clearBotData());
    dispatch(getSpecificBotData(botId));
  }, [dispatch, botId]);

  useEffect(() => {
    console.log(botData?.language);
  });

  function revertToJson(values: any) {
    let languageToSend = {};
    for (const value of values) {
      languageToSend[value.name] = value;
    }
    return languageToSend;
  }

  function diff(current: Object[], initial: Object[]) {
    setDiffOpen(true);
    setNewText(JSON.stringify(current, null, 2));
    setOldText(JSON.stringify(initial, null, 2));
  }

  return (
    <div className="drawerContent">
      <div id="formOwnScroll">
        {botData && (
          <Fragment>
            {commitFormOpen && (
              <div id="commitForm">
                <CommitForm
                  data={botData}
                  newText={commitValues}
                  base={false}
                  language={true}
                />
              </div>
            )}
            {diffOpen && (
              <DiffDialog
                open={diffOpen}
                setDialogOpen={setDiffOpen}
                oldText={oldText}
                newText={newText}
                previous={false}
              />
            )}
            <Formik
              initialValues={{
                language: botData?.language ? Object.values(botData.language) : Object.values(emptyLanguageKey),
              }}
              validationSchema={object({
                language: array(
                  object({
                    name: string().required(
                      'language key name is a required field',
                    ),
                    en: string().required('en is a required field'),
                  }),
                ),
              })}
              onSubmit={async (values) => {
                setCommitValues(revertToJson(values.language));
                dispatch(setCommitFormOpen());
              }}
            >
              {({ values, isSubmitting, initialValues, isValid }) => (
                <Form>
                  <Fragment>
                    <FieldArray name="language">
                      {({ push, remove }) => (
                        <div>
                          <div id="topButtons">
                            <Prompt
                              when={
                                !isEqual(
                                  initialValues.language,
                                  values.language,
                                )
                              }
                              message="You have unsaved changes, are you sure you want to leave?"
                            />
                            <Button
                              disabled={
                                isSubmitting ||
                                !isValid ||
                                isEqual(
                                  initialValues.language,
                                  values.language,
                                ) ||
                                commitFormOpen
                              }
                              type="submit"
                              color="secondary"
                              variant="contained"
                            >
                              {commitFormOpen ? 'Committing' : 'Commit Changes'}
                            </Button>
                            <Button
                              variant="contained"
                              className={classes.button}
                              onClick={() => routeHistory.push(`/bot-manager/${botId}/language/edits-history`)}
                              startIcon={<HistoryIcon />}
                            >
                              History
                            </Button>
                            <Button
                              variant="contained"
                              className={classes.button}
                              onClick={() =>
                                diff(values.language, initialValues.language)
                              }
                              startIcon={<CompareArrowsIcon />}
                              disabled={isEqual(
                                initialValues.language,
                                values.language,
                              )}
                            >
                              Diff
                            </Button>
                            <Button
                              variant="contained"
                              className={classes.button}
                              onClick={() => push(emptyLanguageKey)}
                              startIcon={<AddIcon />}
                            >
                              Add
                            </Button>
                          </div>
                          {values.language.map((_, index) => (
                            <div id="textFields" key={index}>
                              <Field
                                name={`language[${index}].name`}
                                component={TextField}
                                label="name"
                                variant="outlined"
                                disabled={commitFormOpen}
                                style={{ marginBottom: '10px' }}
                              />
                              <Field
                                name={`language[${index}].en`}
                                component={TextField}
                                label="englishDescription"
                                variant="outlined"
                                disabled={commitFormOpen}
                              />
                              <IconButton
                                color="primary"
                                component="button"
                                disabled={
                                  values.language.length === 1 || commitFormOpen
                                }
                                onClick={() => remove(index)}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </Fragment>
                </Form>
              )}
            </Formik>
          </Fragment>
        )}
      </div>
    </div>
  );
}
