import { RootState } from './../store';
import { createSlice } from "@reduxjs/toolkit";

interface jsonState {
   commitFormOpen: boolean;

}

const initialState: jsonState = {
   commitFormOpen: false,
};

export const jsonSlice = createSlice({
   name: "json",
   initialState,
   reducers: {
      setCommitFormOpen: (state) => {
         state.commitFormOpen = true;
      },
      setCommitFormClose: (state) => {
         state.commitFormOpen = false;
      }
   },
});

export const { setCommitFormOpen, setCommitFormClose } = jsonSlice.actions;

export const selectCommitForm = (state:RootState) => state.jsonReducer.commitFormOpen;

export default jsonSlice.reducer;
