import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import JSONEditorApp from "../app/json-editor/JSONEditorApp/JSONEditorApp";
import {
getRemoteConfigChatBot,
selectRemoteChatBot,
} from "../features/bots/botSlice";
import "./DrawerContent.css";

export default function BaseGroupScenario() {
  const dispatch = useDispatch();
  const remoteChatBot = useSelector(selectRemoteChatBot);

  useEffect(() => {
    dispatch(getRemoteConfigChatBot());
  }, [dispatch]);

  return (
    <div>
      {remoteChatBot.baseGroupScenario && (
        <div className="drawerContent">
          <JSONEditorApp
            json={JSON.parse(remoteChatBot.baseGroupScenario)}
            data={"baseGroupScenario"}
            base={true}
          />
        </div>
      )}
    </div>
  );
}
