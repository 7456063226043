import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommitForm from "./commit/CommitForm";
import {
  selectCommitForm,
  setCommitFormClose,
  setCommitFormOpen,
} from "./jsonSlice";
import JSONEditorReact from "./JSONEditor";
import SendIcon from "@material-ui/icons/Send";
import { Button, makeStyles, createStyles, Box } from "@material-ui/core";
import './JSONEditorApp/JSONEditorApp.css'
import GetAppIcon from "@material-ui/icons/GetApp";
import downloadString from "./download/downloadString";
import { routeHistory } from "../../routing/history";
import HistoryIcon from "@material-ui/icons/History";
import MuiDialog from "./Dialog";
import { Prompt } from "react-router-dom";
import PublishIcon from "@material-ui/icons/Publish";
import isEqual from "lodash.isequal";
import DiffDialog from "./DiffDialog";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: "#2196f3",
      color: "white",
    },
    name: {
      backgroundColor: "#E5E2E2",
      color: "black",
    },
    download: {
      backgroundColor: "#59AD23",
      color: "white",
    },
    upload: {
      backgroundColor: "#EE6811",
      color: "white",
    },
    clear: {
      backgroundColor: "#F80606",
      color: "white",
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  })
);

const modes = ["tree", "form", "view", "code", "text"];

export default function JSONEditorGroup({ json, data }) {
  const [text, setText] = useState(JSON.stringify(json, null, 2));
  const [mode, setMode] = useState("code");
  const dispatch = useDispatch();
  const commitFormOpen = useSelector(selectCommitForm);
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [diffOpen, setDiffOpen] = useState(false);

  const [jsonError, setJsonError] = useState();
  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatch(setCommitFormClose());
  }, [dispatch]);

  function onChangeText(text) {
    setText(text);
  }

  useEffect(() => {
    if (!jsonError) {
      try {
        if (isEqual(JSON.parse(text), json)) {
          setDisabled(true);
          window.removeEventListener("beforeunload", beforeUnload);
        } else {
          window.addEventListener("beforeunload", beforeUnload);
          setDisabled(false);
        }
      } catch (e) {
        console.log("error");
      }
    } else {
      setDisabled(true);
    }
  }, [text, json, jsonError]);

  function beforeUnload(e) {
    var confirmationMessage =
      "It looks like you have been editing something. " +
      "If you leave before saving, your changes will be lost.";

    (e || window.event).returnValue = "confirmationMessage";
    return confirmationMessage;
  }

  function onModeChange(mode) {
    setMode(mode);
  }

  function handleInput(e) {
    const type = e.target.files[0].type;
    if (type === "text/plain") {
      const reader = new FileReader();
      reader.onload = function () {
        setText(reader.result);
      };
      reader.readAsText(e.target.files[0]);
    } else {
      alert("You need to choose a .txt file");
    }
  }

  function handleValidation(e) {
    if (_isMounted.current) {
      if (e[0]) {
        setJsonError(e[0].type === "error" ? true : false);
      } else {
        setJsonError(false);
      }
    }
  }

  return (
    <div className="app">
      <div className="contents">
        <Prompt
          when={!disabled}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        {!commitFormOpen && (
          <div>
            <Box className={classes.root}>
              <Button
                variant="contained"
                className={classes.name}
                onClick={() => setDialogOpen(true)}
              >
                @{data.name}
              </Button>
              {data && dialogOpen && (
                <MuiDialog
                  setDialogOpen={setDialogOpen}
                  open={dialogOpen}
                  data={data}
                />
              )}
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => dispatch(setCommitFormOpen())}
                startIcon={<SendIcon />}
                disabled={disabled}
              >
                Commit changes
              </Button>

              <Button
                variant="contained"
                className={classes.button}
                onClick={() =>
                  routeHistory.push(
                    `/bot-manager/${data.id}/group-json/edits-history`
                  )
                }
                startIcon={<HistoryIcon />}
              >
                Group History
              </Button>
              <Button
                variant="contained"
                className={classes.download}
                onClick={() => downloadString(text, data.title)}
                startIcon={<GetAppIcon />}
              >
                Download
              </Button>
              <Button
                className={classes.upload}
                variant="contained"
                component="label"
                startIcon={<PublishIcon />}
              >
                Upload
                <input type="file" hidden onChange={handleInput} />
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => setDiffOpen(true)}
                startIcon={<CompareArrowsIcon />}
                disabled={disabled}
              >
                Diff
              </Button>
            </Box>
          </div>
        )}
        {commitFormOpen && (
          <CommitForm data={data} newText={text} group={true} />
        )}
        <JSONEditorReact
          onValidationError={(e) => handleValidation(e)}
          text={text}
          mode={mode}
          modes={modes}
          indentation={4}
          onChangeText={onChangeText}
          onModeChange={onModeChange}
        />
        {diffOpen && (
          <DiffDialog
            open={diffOpen}
            setDialogOpen={setDiffOpen}
            oldText={JSON.stringify(json, null, 2)}
            newText={JSON.stringify(JSON.parse(text), null, 2)}
            previous={false}
          />
        )}
      </div>
    </div>
  );
}
