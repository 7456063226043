import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { RootState } from '../../app/store';

interface RemoteConfigState {
  general: GeneralRemoteConfigState;
}

interface GeneralRemoteConfigState {
  superAdminUserIds: string[];
}

const initialState: RemoteConfigState = {
  general: {
    superAdminUserIds: []
  }
};

const remoteConfigSlice = createSlice({
    name: 'remoteConfig',
    initialState,
    reducers: {
        setGeneral: (state, action: PayloadAction<GeneralRemoteConfigState>) => {
          state.general = action.payload;
        },
    },
});

export const {
  setGeneral,
} = remoteConfigSlice.actions;

export const initializeRemoteConfig =
  () =>
  (dispatch) => {
    let db = firebase.firestore();
    let generalRCRef = db
      .collection('remote_config')
      .doc('general');
    generalRCRef.get().then((querySnapshot) => {
      const data = querySnapshot.data();
      dispatch(setGeneral(data as GeneralRemoteConfigState));
    });
  };
  
export const selectGeneralRemoteConfig = (state: RootState) => state.remoteConfigReducer.general;

export default remoteConfigSlice.reducer