import React, { Fragment, useState } from 'react';
import { Button, IconButton, MenuItem } from '@material-ui/core';
import { Field, FieldArray, Form, Formik } from 'formik';
import { object, string, array } from 'yup';
import { TextField } from 'formik-material-ui';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './CommandForm.css';
import _ from 'lodash';
import CommitForm from '../commit/CommitForm';
import { selectCommitForm, setCommitFormOpen } from '../jsonSlice';
import { useDispatch, useSelector } from 'react-redux';
import HistoryIcon from '@material-ui/icons/History';
import { routeHistory } from '../../../routing/history';
import DiffDialog from '../DiffDialog';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { Prompt } from 'react-router-dom';
import { useEffect } from 'react';

var isEqual = function (initialValues: Object[], values: Object[]) {
  if (initialValues.length === values.length) {
    return _(initialValues).differenceWith(values, _.isEqual).isEmpty();
  } else {
    return false;
  }
};

const emptyCommand = {
  name: '',
  command: '',
  description: '',
  userPermission: '',
};

export default function CommandForm({ json, data, classes, loaded }) {
  const [diffOpen, setDiffOpen] = useState<boolean>(false);
  const [commitValues, setCommitValues] = useState<Object>();
  const [oldText, setOldText] = useState<string>();
  const [newText, setNewText] = useState<string>();
  const dispatch = useDispatch();
  const commitFormOpen = useSelector(selectCommitForm);
  const [initialValues, setInitialValues] = useState<Object[]>();

  function revertToJson(values: Object[]) {
    let jsonToSend = {};
    for (const command of values) {
      jsonToSend[command['name']] = command;

      // userPermission to Array
      if (jsonToSend[command['name']]['userPermission']?.length === 0) {
        jsonToSend[command['name']]['userPermission'] = [];
      } else {
        jsonToSend[command['name']]['userPermission'] = [
          jsonToSend[command['name']]['userPermission'],
        ];
      }

      delete jsonToSend[command['name']]['name'];
    }
    console.log(jsonToSend);
    return jsonToSend;
  }

  function diff(current: Object[], initial: Object[]) {
    setDiffOpen(true);
    setNewText(JSON.stringify(current, null, 2));
    setOldText(JSON.stringify(initial, null, 2));
  }

  useEffect(() => {
    let initialArray = [];
    for (const key in json) {
      let withName = {
        ...json[key],
        name: key,
        userPermission: json[key].userPermission[0] || '',
      };
      initialArray.push(withName);
    }
    setInitialValues(initialArray);
  }, [json]);

  return (
    <div id="formOwnScroll">
      {commitFormOpen && (
        <div id="commitForm">
          <CommitForm
            data={data}
            newText={commitValues}
            base={true}
            loaded={loaded}
          />
        </div>
      )}
      {diffOpen && (
        <DiffDialog
          open={diffOpen}
          setDialogOpen={setDiffOpen}
          oldText={oldText}
          newText={newText}
          previous={false}
        />
      )}
      {initialValues && (
        <Formik
          initialValues={{
            command: initialValues,
          }}
          validationSchema={object({
            command: array(
              object({
                name: string().required('name key name is a required field'),
                command: string().required('command is a required field'),
                description: string().required(
                  'description is a required field',
                ),
              }),
            ),
          })}
          onSubmit={async (values) => {
            setCommitValues(revertToJson(values.command));
            dispatch(setCommitFormOpen());
          }}
        >
          {({ values, isSubmitting, initialValues, isValid }) => (
            <Form>
              <Fragment>
                <FieldArray name="command">
                  {({ remove, insert }) => (
                    <div>
                      <div id="topButtons">
                        <Prompt
                          when={!isEqual(initialValues.command, values.command)}
                          message="You have unsaved changes, are you sure you want to leave?"
                        />
                        <Button
                          disabled={
                            isSubmitting ||
                            !isValid ||
                            isEqual(initialValues.command, values.command) ||
                            commitFormOpen
                          }
                          type="submit"
                          color="secondary"
                          variant="contained"
                        >
                          {commitFormOpen ? 'Committing' : 'Commit Changes'}
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => routeHistory.push('/base-history')}
                          startIcon={<HistoryIcon />}
                        >
                          History
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() =>
                            diff(values.command, initialValues.command)
                          }
                          startIcon={<CompareArrowsIcon />}
                          disabled={isEqual(
                            initialValues.command,
                            values.command,
                          )}
                        >
                          Diff
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => insert(0, emptyCommand)}
                          startIcon={<AddIcon />}
                        >
                          Add
                        </Button>
                      </div>
                      {values.command.map((_: string, index: number) => (
                        <div key={index}>
                          <div className="commandRow">
                            <Field
                              name={`command[${index}].name`}
                              component={TextField}
                              label="name"
                              variant="outlined"
                              disabled={commitFormOpen}
                              className="commandNameField"
                            />
                            <Field
                              name={`command[${index}].command`}
                              component={TextField}
                              label="command"
                              variant="outlined"
                              disabled={commitFormOpen}
                            />
                          </div>
                          <div className="secondRowRemove">
                            <div className="secondRow">
                              <Field
                                name={`command[${index}].description`}
                                component={TextField}
                                label="description"
                                variant="outlined"
                                disabled={commitFormOpen}
                                className="descriptionField"
                              />
                              <Field
                                component={TextField}
                                type="text"
                                name={`command[${index}].userPermission`}
                                label="userPermission"
                                select
                                variant="outlined"
                                disabled={commitFormOpen}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                <MenuItem value={''}>Null</MenuItem>
                                <MenuItem value="Class Organiser">
                                  Class Organiser
                                </MenuItem>
                                <MenuItem value="Tumla Admin">
                                  Tumla Admin
                                </MenuItem>
                              </Field>
                            </div>
                            <IconButton
                              className="remove"
                              color="primary"
                              component="button"
                              disabled={
                                values.command.length === 1 || commitFormOpen
                              }
                              onClick={() => remove(index)}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </Fragment>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
