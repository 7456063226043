import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import JSONEditorGroup from "../app/json-editor/JSONEditorGroup";
import {
  clearBotData,
  getSpecificBotData,
  selectBotData,
} from "../features/bots/botSlice";
import "./DrawerContent.css";

export default function SpecificGroup() {
  const dispatch = useDispatch();
  const currentBotData = useSelector(selectBotData);
  const botData = currentBotData[0];

  let { botId } = useParams<{ botId: string }>();

  useEffect(() => {
    dispatch(clearBotData());
    dispatch(getSpecificBotData(botId));
  }, [dispatch, botId]);

  return (
    <div>
      {botData && (
        <div className="drawerContent">
          <JSONEditorGroup
            json={
              botData?.groupScenario
                ? JSON.parse(botData.groupScenario)
                : JSON.parse(botData.groupScenarioBlocks || null)
            }
            data={botData}
          />
        </div>
      )}
    </div>
  );
}
