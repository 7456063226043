import "../pages/DrawerContent.css";
import React from "react";
import { Formik, Form, Field } from "formik";
import { Button, makeStyles, Theme, createStyles } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Box from "@material-ui/core/Box";
import SendIcon from "@material-ui/icons/Send";
import firebase from "firebase/app";
import "firebase/functions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      button: {
         backgroundColor: "#2196f3",
         color: "white",
      },
   })
);

interface Values {
   email: string;
}

const initialValues: Values = {
   email: "",
};

const validationSchema = Yup.object().shape({
   email: Yup.string().email().required("You must have an email"),
});

toast.configure();

export default function ForgotPassword() {
   const classes = useStyles();

   return (
      <div className='forgotPassword'>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm, setSubmitting }) => {
               firebase
                  .auth()
                  .sendPasswordResetEmail(values.email)
                  .then(function () {
                     toast.success(`Email sent to ${values.email} 😁`);
                  })
                  .catch(function (error) {
                     toast.error(error.message);
                  });
               setSubmitting(false);
               resetForm();
            }}
         >
            {({ submitForm, isValid, dirty, isSubmitting }) => (
               <Form>
                  <Box margin={1}>
                     <Field
                        component={TextField}
                        name='email'
                        type='text'
                        label='Email'
                     />
                     <Button
                        variant='contained'
                        className={classes.button}
                        disabled={isSubmitting || !isValid || !dirty}
                        onClick={submitForm}
                        startIcon={<SendIcon />}
                     >
                        Send
                     </Button>
                  </Box>
               </Form>
            )}
         </Formik>
      </div>
   );
}
