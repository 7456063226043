import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCommitFormClose } from '../jsonSlice';
import { makeStyles, createStyles } from '@material-ui/core';
import './JSONEditorApp.css';
import isEqual from 'lodash.isequal';
import NotBaseJSON from './NotBaseJSON';
import BaseJSON from './BaseJSON';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: '#2196f3',
      color: 'white',
    },
    name: {
      backgroundColor: '#E5E2E2',
      color: 'black',
    },
    download: {
      backgroundColor: '#59AD23',
      color: 'white',
    },
    upload: {
      backgroundColor: '#EE6811',
      color: 'white',
    },
    clear: {
      backgroundColor: '#F80606',
      color: 'white',
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

const modes = ['tree', 'form', 'view', 'code', 'text'];

export default function JSONEditorApp({
  json,
  data,
  loaded = null,
  base = false,
}) {
  const [text, setText] = useState(JSON.stringify(json, null, 2));
  const [mode, setMode] = useState('code');
  const dispatch = useDispatch();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [jsonError, setJsonError] = useState();
  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatch(setCommitFormClose());
  }, [dispatch]);

  function onChangeText(text) {
    setText(text);
  }

  useEffect(() => {
    if (!jsonError) {
      try {
        if (isEqual(JSON.parse(text), json)) {
          setDisabled(true);
          window.removeEventListener('beforeunload', beforeUnload);
        } else {
          window.addEventListener('beforeunload', beforeUnload);
          setDisabled(false);
        }
      } catch (e) {
        console.log('error');
      }
    } else {
      setDisabled(true);
    }
  }, [text, json, jsonError]);

  function beforeUnload(e) {
    var confirmationMessage =
      'It looks like you have been editing something. If you leave before saving, your changes will be lost.';

    (e || window.event).returnValue = 'confirmationMessage';
    return confirmationMessage;
  }

  function onModeChange(mode) {
    setMode(mode);
  }

  function handleValidation(e) {
    if (_isMounted.current) {
      if (e[0]) {
        setJsonError(e[0].type === 'error' ? true : false);
      } else {
        setJsonError(false);
      }
    }
  }

  function handleInput(e) {
    const type = e.target.files[0].type;
    if (type === 'text/plain') {
      const reader = new FileReader();
      reader.onload = function () {
        setText(reader.result);
      };
      reader.readAsText(e.target.files[0]);
    } else {
      alert('You need to choose a .txt file.');
    }
  }

  return (
    <div className="app">
      <div className="contents">
        {base && (
          <BaseJSON
            classes={classes}
            disabled={disabled}
            data={data}
            text={text}
            handleInput={handleInput}
            loaded={loaded}
            mode={mode}
            modes={modes}
            handleValidation={handleValidation}
            onChangeText={onChangeText}
            onModeChange={onModeChange}
            json={json}
          />
        )}
        {!base && (
          <NotBaseJSON
            classes={classes}
            disabled={disabled}
            data={data}
            text={text}
            handleInput={handleInput}
            loaded={loaded}
            mode={mode}
            modes={modes}
            handleValidation={handleValidation}
            onChangeText={onChangeText}
            onModeChange={onModeChange}
            json={json}
          />
        )}
      </div>
    </div>
  );
}
