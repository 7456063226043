import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

export default function DiffDialog({ open, setDialogOpen, oldText, newText, previous }) {
  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {previous ? <div>Previous Version</div> : <div>Current Version</div>}
          <div>This Version</div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div id="alert-dialog-description">
          <ReactDiffViewer
            oldValue={oldText}
            newValue={newText}
            compareMethod={DiffMethod.WORDS}
            splitView={true}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
