import React, { Fragment, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Button,
  makeStyles,
  createStyles,
  LinearProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import SendIcon from '@material-ui/icons/Send';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import { setCommitFormClose } from '../jsonSlice';
import { useDispatch } from 'react-redux';
import './CommitForm.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getInactiveBaseJson,
  getRemoteConfigChatBot,
} from '../../../features/bots/botSlice';
import { routeHistory } from '../../../routing/history';
import { useEffect } from 'react';
import { axiosInstance } from '../../..';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: '#2196f3',
      color: 'white',
    },
    checkBoxes: {
      margin: 'auto',
    },
    formElement: {
      textAlign: 'center',
    },
  }),
);

interface Values {
  message: string;
}

const initialValues: Values = {
  message: '',
};

toast.configure();

export default function CommitFormInactive({ inactiveJson, activeBaseJson }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [noChanges, setNoChanges] = useState(false);
  const [whichJson, setWhichJson] = useState({
    baseScenario: false,
    baseGroupScenario: false,
    commandDM: false,
    commandGroup: false,
    language: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newState = { ...whichJson };
    newState[event.target.name] = event.target.checked;
    setWhichJson(newState);
  };

  useEffect(() => {
    console.log(inactiveJson, activeBaseJson);
  }, [inactiveJson, activeBaseJson]);

  useEffect(() => {
    let countFalses = 0;
    for (const key in whichJson) {
      if (!whichJson[key]) {
        countFalses++;
      }
    }
    setNoChanges(countFalses === 5  ? true : false);
  }, [whichJson]);

  return (
    <Fragment>
      <FormGroup row className={classes.checkBoxes}>
        <FormControlLabel
          control={
            <Checkbox
              checked={whichJson.baseScenario}
              onChange={handleChange}
              name="baseScenario"
							disabled={inactiveJson.baseScenario === activeBaseJson.baseScenario}
            />
          }
          label="baseScenario"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={whichJson.baseGroupScenario}
              onChange={handleChange}
              name="baseGroupScenario"
							disabled={inactiveJson.baseGroupScenario === activeBaseJson.baseGroupScenario}
            />
          }
          label="baseGroupScenario"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={whichJson.commandDM}
              onChange={handleChange}
              name="commandDM"
							disabled={inactiveJson.commandDM === activeBaseJson.commandDM}
            />
          }
          label="commandDM"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={whichJson.commandGroup}
              onChange={handleChange}
              name="commandGroup"
							disabled={inactiveJson.commandGroup === activeBaseJson.commandGroup}
            />
          }
          label="commandGroup"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={whichJson.language}
              onChange={handleChange}
              name="language"
							disabled={inactiveJson.language === activeBaseJson.language}
            />
          }
          label="language"
        />
      </FormGroup>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          setDisabled(true);
          axiosInstance.post('upsertChatBot', {
            id: 'chat-bot',
            baseScenario: whichJson.baseScenario
              ? inactiveJson.baseScenario
              : activeBaseJson.baseScenario,
            baseGroupScenario: whichJson.baseGroupScenario
              ? inactiveJson.baseGroupScenario
              : activeBaseJson.baseGroupScenario,
            commandGroup: whichJson.commandGroup
              ? inactiveJson.commandGroup
              : activeBaseJson.commandGroup,
            commandDM: whichJson.commandDM
              ? inactiveJson.commandDM
              : activeBaseJson.commandDM,
            language: whichJson.language
              ? inactiveJson.language
              : activeBaseJson.language,
            message: values.message,
          })
            .then(function (result) {
              dispatch(setCommitFormClose());
              console.log('DATA SENT!', result.data);
              routeHistory.push('/base-scenario');
              toast.success('🚀 DATA SENT AND JSON REVERTED!', {
                autoClose: false,
              });
              dispatch(getInactiveBaseJson());
              dispatch(getRemoteConfigChatBot());
            })
            .catch(function (error) {
              var code = error.code;
              var message = error.message;
              var details = error.details;
              dispatch(setCommitFormClose());
              toast.error('🙄 SORRY, THERE WAS A PROBLEM!');
              console.error(
                'SORRY, THERE WAS A PROBLEM!',
                code,
                message,
                details,
              );
            });
          resetForm();
        }}
      >
        {({ submitForm }) => (
          <Form className={classes.formElement}>
            <Box margin={1}>
              <Field
                component={TextField}
                name="message"
                type="text"
                label="Message"
              />
              <Button
                variant="contained"
                className={classes.button}
                disabled={disabled || noChanges}
                onClick={submitForm}
                startIcon={<SendIcon />}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => dispatch(setCommitFormClose())}
                startIcon={<CancelScheduleSendIcon />}
                disabled={disabled}
              >
                Cancel
              </Button>
            </Box>
            {disabled && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
