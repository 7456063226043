import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRemoteConfigChatBot, selectRemoteChatBot } from '../features/bots/botSlice';
import './DrawerContent.css';
import JSONEditorApp from '../app/json-editor/JSONEditorApp/JSONEditorApp';

export default function LanguageKeys() {
  const dispatch = useDispatch();
  const remoteChatBot = useSelector(selectRemoteChatBot);

  useEffect(
    () => {
      dispatch(getRemoteConfigChatBot());
    },
    [ dispatch ],
  );

  return (
    <div>
      {remoteChatBot.language && (
        <div className="drawerContent">
          <JSONEditorApp json={remoteChatBot.language} data={'languageKeys'} base={true} />
        </div>
      )}
    </div>
  );
}
