import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBotHistoryData,
  selectBotData,
  selectFilteredBotData,
  setFilteredBotData,
} from '../features/bots/botSlice';
import { Link, useParams } from 'react-router-dom';
import './DrawerContent.css';
import {
  Card,
  CardContent,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Tooltip,
  Typography,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import uid from 'uid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from '@material-ui/icons/Info';
import { BotData } from '../types/BotData';

toast.configure();

const useStyles = makeStyles({
  root: {
    width: 290,
    height: 165,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  link: {
    textDecoration: 'none',
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  firstRow: {
    display: 'flex',
  },
  header: {
    display: 'flex',
    marginBottom: '10px',
    width: '100%',
  },
  filterFields: {
    width: '200px',
    marginLeft: '10px',
  },
  botTitle: {
    alignSelf: 'center',
  },
});

export default function EditsHistory() {
  const dispatch = useDispatch();
  const historyData = useSelector(selectBotData);
  const filteredData = useSelector(selectFilteredBotData);
  const classes = useStyles();
  const [availableModified, setAvailableModified] = useState<string[]>([]);
  const [modifiedFilter, setModifiedFilter] = useState<string>('');
  const [commitFilter, setCommitFilter] = useState<string>('');
  const [changedFilter, setChangedFilter] = useState<string[]>([]);

  const loc = window.location.pathname;
  let { botId } = useParams<{ botId: string }>();

  function linkTo(data: BotData) {
    const dataIndex = historyData.indexOf(data);
    const previousId = historyData[dataIndex + 1]?.histId;
    if (loc.includes('group-json')) {
      return `/bot-manager/${data.id}/group-json/edits-history/${data.histId}/pre=${previousId}`;
    } else if (loc.includes('language')) {
      return `/bot-manager/${data.id}/language/edits-history/${data.histId}/pre=${previousId}`;
    } else {
      return `/bot-manager/${data.id}/edits-history/${data.histId}/pre=${previousId}`;
    }
  }

  useEffect(() => {
    dispatch(getBotHistoryData(botId));
  }, [dispatch, botId]);

  useEffect(() => {
    dispatch(setFilteredBotData(historyData));
  }, [dispatch, historyData]);

  function copyId(e: any, histId: string) {
    e.preventDefault();
    navigator.clipboard.writeText(histId);
    toast.success(`📎  ${histId} copied to clipboard!`);
  }

  function updatedToShow(date: string) {
    return date.replace('T', ' ').slice(0, -5);
  }

  useEffect(() => {
    let modifiedArray = [];
    if (historyData) {
      for (const data of historyData) {
        if (!modifiedArray.includes(data.modifiedByName)) {
          modifiedArray.push(data.modifiedByName);
        }
      }
      setAvailableModified(modifiedArray);
    }
  }, [historyData]);

  const handleModifiedFilter = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string;
    setModifiedFilter(value);
  };

  const handleChangedFilter = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string[];
    setChangedFilter(value);
  };

  const handleCommitFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCommitFilter(value);
  };

  useEffect(() => {
    let newHistoryData = [...historyData];

    if (modifiedFilter.length > 0) {
      newHistoryData = newHistoryData.filter(
        (data) => data.modifiedByName === modifiedFilter,
      );
    }

    if (commitFilter.length > 0) {
      newHistoryData = newHistoryData.filter(
        (data) => data.message.indexOf(commitFilter) >= 0,
      );
    }

    if (changedFilter.length > 0) {
      newHistoryData = newHistoryData.filter((data) => {
        for (const filter of changedFilter) {
          if (!data.changedFields?.includes(filter)) {
            return false;
          }
        }
        return true;
      });
    }

    dispatch(setFilteredBotData(newHistoryData));
  }, [changedFilter, commitFilter, dispatch, historyData, modifiedFilter]);

  return (
    <div className="drawerFlex" style={{ flexDirection: 'column' }}>
      <div className={classes.header}>
        <Typography variant="h5" component="h2" className={classes.botTitle}>
          {filteredData[0]?.title}
        </Typography>
        <div>
          <FormControl variant="outlined">
            <InputLabel>ModifiedBy</InputLabel>
            <Select
              className={classes.filterFields}
              value={modifiedFilter}
              onChange={handleModifiedFilter}
              label="ModifiedBy"
            >
              <MenuItem value="">All</MenuItem>
              {availableModified?.map((modified: string, index: number) => (
                <MenuItem key={index} value={modified}>
                  {modified}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel>Changed Fields</InputLabel>
            <Select
              className={classes.filterFields}
              multiple
              value={changedFilter}
              onChange={handleChangedFilter}
              label="Changed Fields"
            >
              <MenuItem value="groupScenario">groupScenario</MenuItem>
              <MenuItem value="groupScenarioBlocks">
                groupScenarioBlocks
              </MenuItem>
              <MenuItem value="scenario">scenario</MenuItem>
              <MenuItem value="scenarioBlocks">scenarioBlocks</MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={classes.filterFields}
            label="Commit Message"
            variant="outlined"
            onChange={handleCommitFilter}
            value={commitFilter}
          />
        </div>
      </div>
      <div className={classes.cards}>
        {filteredData?.map((data, index) => (
          <div key={uid()}>
            <Link to={linkTo(data)} className={classes.link}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <div className={classes.firstRow}>
                    <Typography className={classes.pos} color="textSecondary">
                      Updated: {updatedToShow(data.updated)}
                    </Typography>
                    <Tooltip title={data.histId + ' (Click to Copy)'}>
                      <IconButton onClick={(e) => copyId(e, data.histId)}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Typography variant="body2" component="p">
                    Modified By: <b>{data.modifiedByName}</b>
                  </Typography>
                  {data.message?.length > 0 && (
                    <Typography variant="body2" component="p">
                      Commit Message:
                      <b> {`"${data.message}"`}</b>
                    </Typography>
                  )}
                  {data.changedFields?.length > 0 && (
                    <Typography variant="body2" component="p">
                      Changed:
                      <b> {`"${data.changedFields}"`}</b>
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
