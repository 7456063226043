import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommitForm from '../commit/CommitForm';
import {
  selectCommitForm,
  setCommitFormClose,
  setCommitFormOpen,
} from '../jsonSlice';
import JSONEditorReact from '../JSONEditor';
import SendIcon from '@material-ui/icons/Send';
import { Button, makeStyles, createStyles, Box } from '@material-ui/core';
import '../JSONEditorApp/JSONEditorApp.css';
import GetAppIcon from '@material-ui/icons/GetApp';
import downloadString from '../download/downloadString';
import {
  getSpecificBotData,
  selectBotDiff,
  selectPreviousBotData,
} from '../../../features/bots/botSlice';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import DiffDialog from '../DiffDialog';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: '#2196f3',
      color: 'white',
    },
    name: {
      backgroundColor: '#E5E2E2',
      color: 'black',
    },
    download: {
      backgroundColor: '#59AD23',
      color: 'white',
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

export default function JSONBotViewer({
  json,
  data,
  parentId = null,
  blocks = false,
  groupJson = false,
}) {
  const text = JSON.stringify(json, null, 2);
  const [diffCurrent, setDiffCurrent] = useState(false);
  const dispatch = useDispatch();
  const commitFormOpen = useSelector(selectCommitForm);
  const classes = useStyles();
  const currentDiff = useSelector(selectBotDiff);
  const [currentText, setCurrentText] = useState();
  const [previousText, setPreviousText] = useState();
  const [diffPrevious, setDiffPrevious] = useState(false);
  const [equalPrevious, setEqualPrevious] = useState(false);
  const [equalCurrent, setEqualCurrent] = useState(false);
  const loc = window.location.pathname;
  let { botId } = useParams();

  const languageHist = loc.includes('language');

  const previousBotData = useSelector(selectPreviousBotData);
  const previousBot = previousBotData && previousBotData[0];

  useEffect(() => {
    if (languageHist) {
      setCurrentText(currentDiff[0]?.language);
      setPreviousText(previousBot?.language);
    } else if (blocks) {
      if (groupJson) {
        setCurrentText(currentDiff[0]?.groupScenarioBlocks);
        setPreviousText(previousBot?.groupScenarioBlocks);
      } else {
        setCurrentText(currentDiff[0]?.scenarioBlocks);
        setPreviousText(previousBot?.scenarioBlocks);
      }
    } else {
      if (groupJson) {
        setCurrentText(
          currentDiff[0].groupScenario || currentDiff[0].groupScenarioBlocks,
        );
        setPreviousText(
          previousBot.groupScenario || previousBot.groupScenarioBlocks,
        );
      } else {
        setCurrentText(currentDiff[0].scenario);
        setPreviousText(previousBot.scenario);
      }
    }
  }, [blocks, currentDiff, groupJson, data, languageHist, previousBot]);

  useEffect(() => {
    dispatch(setCommitFormClose());
    dispatch(getSpecificBotData(botId, true));
  }, [dispatch, botId]);

  useEffect(() => {
    if (languageHist) {
      if (currentText && text) {
        setEqualCurrent(
          JSON.stringify(currentText, null, 2) ===
            JSON.stringify(text, null, 2),
        );
      }
      if (previousText && text) {
        setEqualPrevious(
          JSON.stringify(previousText, null, 2) ===
            JSON.stringify(text, null, 2),
        );
      }
    } else {
      if (currentText && text) {
        setEqualCurrent(
          JSON.stringify(JSON.parse(currentText), null, 2) ===
            JSON.stringify(JSON.parse(text), null, 2),
        );
      }
      if (previousText && text) {
        setEqualPrevious(
          JSON.stringify(JSON.parse(previousText), null, 2) ===
            JSON.stringify(JSON.parse(text), null, 2),
        );
      }
    }
  }, [text, currentText, previousText, languageHist]);

  return (
    <div className="app">
      <div className="contents">
        {!commitFormOpen && (
          <div>
            <Box className={classes.root}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => dispatch(setCommitFormOpen())}
                startIcon={<SendIcon />}
                disabled={equalCurrent}
              >
                Back to this version
              </Button>
              <Button
                variant="contained"
                className={classes.download}
                onClick={() => downloadString(text, data.title, true)}
                startIcon={<GetAppIcon />}
              >
                Download
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => setDiffCurrent(true)}
                startIcon={<CompareArrowsIcon />}
                disabled={equalCurrent}
              >
                Diff Current
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => setDiffPrevious(true)}
                startIcon={<CompareArrowsIcon />}
                disabled={equalPrevious || !previousBot}
              >
                {previousBot ? 'Diff Previous' : 'Previous Not Available'}
              </Button>
            </Box>
          </div>
        )}
        {commitFormOpen && (
          <CommitForm
            newText={text}
            data={data}
            parentId={parentId}
            group={groupJson}
            base={false}
            language={languageHist}
          />
        )}
        <JSONEditorReact text={text} mode={'view'} indentation={4} />
        {diffCurrent && (
          <DiffDialog
            open={diffCurrent}
            setDialogOpen={setDiffCurrent}
            oldText={
              languageHist
                ? JSON.stringify(currentText, null, 2)
                : JSON.stringify(JSON.parse(currentText), null, 2)
            }
            newText={JSON.stringify(JSON.parse(text), null, 2)}
            previous={false}
          />
        )}
        {diffPrevious && (
          <DiffDialog
            open={diffPrevious}
            setDialogOpen={setDiffPrevious}
            oldText={
              languageHist
                ? JSON.stringify(previousText, null, 2)
                : JSON.stringify(JSON.parse(previousText), null, 2)
            }
            newText={JSON.stringify(JSON.parse(text), null, 2)}
            previous={true}
          />
        )}
      </div>
    </div>
  );
}
