import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
   getBotData,
   selectBotData,
   clearBotData,
} from "../features/bots/botSlice";
import { Link } from "react-router-dom";
import "./DrawerContent.css";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import uid from "uid";

const useStyles = makeStyles({
   root: {
      width: 290,
      height: 250,
   },
   bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
   },
   title: {
      fontSize: 14,
   },
   pos: {
      marginBottom: 12,
   },
});

export default function BotManagerPage() {
   const dispatch = useDispatch();
   const clientData = useSelector(selectBotData);
   const classes = useStyles();

   useEffect(() => {
      dispatch(clearBotData());
      dispatch(getBotData());
   }, [dispatch]);

   return (
      <div className='drawerFlex'>
         {clientData?.map((data) => (
            <div key={uid()}>
               <Link
                  to={`/bot-manager/${data.id}`}
                  style={{ textDecoration: "none" }}
               >
                  <Card className={classes.root} variant='outlined'>
                     <CardContent>
                        <Typography
                           className={classes.title}
                           color='textSecondary'
                           gutterBottom
                        >
                           Id: {data.id}
                        </Typography>
                        <Typography variant='h5' component='h2'>
                           {data.title}
                        </Typography>
                        <Typography
                           className={classes.pos}
                           color='textSecondary'
                        >
                           Created: {data.created?.replace("T", " ")}
                        </Typography>
                        <Typography
                           className={classes.pos}
                           color='textSecondary'
                        >
                           Updated: {data.updated?.replace("T", " ")}
                        </Typography>
                        <Typography
                           className={classes.pos}
                           color='textSecondary'
                        >
                           Description: {data.description || null}
                        </Typography>
                     </CardContent>
                  </Card>
               </Link>
            </div>
         ))}
      </div>
   );
}
