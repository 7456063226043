import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { BotData } from '../../types/BotData';
import { firebaseInactive } from '../..';
interface BotDataState {
  botDataList?: Array<BotData>;
  botDiff?: Array<BotData>;
  filteredBotData?: BotData[];
  previousBotData?: BotData[];
  remoteConfigChatBot: any;
  remoteConfigInactiveChatBot: any;
  baseJson:
    | 'baseScenario'
    | 'baseGroupScenario'
    | 'commandDM'
    | 'commandGroup'
    | 'language';
}

interface BotDataPayload {
  botDataList?: BotData[];
  botDiff?: BotData[];
  botData?: BotData;
  previousBotData?: BotData;
}

const initialState: BotDataState = {
  filteredBotData: [],
  botDataList: [],
  botDiff: [],
  remoteConfigChatBot: {},
  remoteConfigInactiveChatBot: {},
  baseJson: 'baseScenario',
};

export const botSlice = createSlice({
  name: 'userApproval',
  initialState,
  reducers: {
    successfulBotData: (state, action: PayloadAction<BotDataPayload>) => {
      state.botDataList = action.payload as Array<BotData>;
    },
    setFilteredBotData: (state, action: PayloadAction<BotDataPayload>) => {
      state.filteredBotData = action.payload as Array<BotData>;
    },
    successfulDiff: (state, action: PayloadAction<BotDataPayload>) => {
      state.botDiff = action.payload as Array<BotData>;
    },
    clearBotData: (state) => {
      state.botDataList = initialState.botDataList;
    },
    setRemoteConfigChatBot: (state, action) => {
      state.remoteConfigChatBot = action.payload;
    },
    setRemoteConfigInactiveChatBot: (state, action) => {
      state.remoteConfigInactiveChatBot = action.payload;
    },
    setBaseJson: (state, action) => {
      state.baseJson = action.payload;
    },
    setPreviousBotData: (state, action) => {
      state.previousBotData = action.payload;
    },
  },
});

export const {
  successfulBotData,
  successfulDiff,
  clearBotData,
  setRemoteConfigChatBot,
  setRemoteConfigInactiveChatBot,
  setBaseJson,
  setFilteredBotData,
  setPreviousBotData,
} = botSlice.actions;

export const getBotData = (): AppThunk => (dispatch) => {
  var arrayResult = [];
  var resultStore = firebase.firestore();
  resultStore
    .collection('CB_bots')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        arrayResult.push(data as BotData);
      });
      dispatch(successfulBotData(arrayResult));
    });
};

export const getBotHistoryData =
  (id: string): AppThunk =>
  (dispatch) => {
    let arrayResult = [];
    let db = firebase.firestore();
    let History_Ref = db.collection('CB_bots').doc(id).collection('history');
    History_Ref.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.histId = doc.id;
        arrayResult.push(data as BotData);
      });
      arrayResult = arrayResult.sort(function (a, b) {
        return a.updated > b.updated ? -1 : b.updated > a.updated ? 1 : 0;
      });
      dispatch(successfulBotData(arrayResult));
    });
  };

export const getBaseHistoryData = (): AppThunk => (dispatch) => {
  let arrayResult = [];
  let db = firebase.firestore();
  let History_Ref = db
    .collection('remote_config')
    .doc('chat-bot')
    .collection('history');
  History_Ref.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      data.histId = doc.id;
      arrayResult.push(data as BotData);
    });
    arrayResult = arrayResult.sort(function (a, b) {
      return a.updated > b.updated ? -1 : b.updated > a.updated ? 1 : 0;
    });
    dispatch(successfulBotData(arrayResult));
  });
};

export const getSpecificBotData =
  (id: string, diff = false): AppThunk =>
  (dispatch) => {
    let db = firebase.firestore();
    let Bots_Ref = db.collection('CB_bots').doc(id);
    Bots_Ref.get().then((querySnapshot) => {
      const data = querySnapshot.data();
      data.id = querySnapshot.id;
      var arrayResult = [];
      arrayResult.push(data as BotData);
      dispatch(
        diff ? successfulDiff(arrayResult) : successfulBotData(arrayResult),
      );
    });
  };

export const getRemoteConfigChatBot = (): AppThunk => (dispatch) => {
  let db = firebase.firestore();
  let Bots_Ref = db.collection('remote_config').doc('chat-bot');
  Bots_Ref.get().then((querySnapshot) => {
    const data = querySnapshot.data();
    data.id = querySnapshot.id;
    dispatch(setRemoteConfigChatBot(data));
  });
};

export const getSpecificHistoryData =
  (id: string, histId: string): AppThunk =>
  (dispatch) => {
    let db = firebase.firestore();
    let Bots_Ref = db
      .collection('CB_bots')
      .doc(id)
      .collection('history')
      .doc(histId);
    Bots_Ref.get().then((querySnapshot) => {
      const data = querySnapshot.data();
      data.id = querySnapshot.id;
      var arrayResult = [];
      arrayResult.push(data as BotData);
      dispatch(successfulBotData(arrayResult));
    });
  };

export const getSpecificPreviousData =
  (id: string, histId: string): AppThunk =>
  (dispatch) => {
    let db = firebase.firestore();
    let Bots_Ref = db
      .collection('CB_bots')
      .doc(id)
      .collection('history')
      .doc(histId);
    Bots_Ref.get().then((querySnapshot) => {
      const data = querySnapshot.data();
      data.id = querySnapshot.id;
      var arrayResult = [];
      arrayResult.push(data as BotData);
      dispatch(setPreviousBotData(arrayResult));
    });
  };

export const getSpecificBaseHistoryData =
  (histId: string): AppThunk =>
  (dispatch) => {
    let db = firebase.firestore();
    let Bots_Ref = db
      .collection('remote_config')
      .doc('chat-bot')
      .collection('history')
      .doc(histId);
    Bots_Ref.get().then((querySnapshot) => {
      const data = querySnapshot.data();
      data.id = querySnapshot.id;
      var arrayResult = [];
      arrayResult.push(data);
      dispatch(successfulBotData(arrayResult));
    });
  };

export const getSpecificBasePreviousData =
  (histId: string): AppThunk =>
  (dispatch) => {
    let db = firebase.firestore();
    let Bots_Ref = db
      .collection('remote_config')
      .doc('chat-bot')
      .collection('history')
      .doc(histId);
    Bots_Ref.get().then((querySnapshot) => {
      const data = querySnapshot.data();
      data.id = querySnapshot.id;
      var arrayResult = [];
      arrayResult.push(data);
      dispatch(setPreviousBotData(arrayResult));
    });
  };

export const getInactiveBaseJson = (): AppThunk => (dispatch) => {
  let db = firebaseInactive.firestore();
  let bots_ref = db.collection('remote_config').doc('chat-bot');
  bots_ref
    .get()
    .then((querySnapshot) => {
      let data = querySnapshot.data();
      data.id = querySnapshot.id;
      dispatch(setRemoteConfigInactiveChatBot(data));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const selectBotData = (state: RootState) => state.botReducer.botDataList;
export const selectFilteredBotData = (state: RootState) =>
  state.botReducer.filteredBotData;
export const selectBotDiff = (state: RootState) => state.botReducer.botDiff;
export const selectRemoteChatBot = (state: RootState) =>
  state.botReducer.remoteConfigChatBot;
export const selectRemoteInactiveChatBot = (state: RootState) =>
  state.botReducer.remoteConfigInactiveChatBot;
export const selectBaseJson = (state: RootState) => state.botReducer.baseJson;
export const selectPreviousBotData = (state: RootState) =>
  state.botReducer.previousBotData;

export default botSlice.reducer;
