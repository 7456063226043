import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import JSONEditorApp from "../app/json-editor/JSONEditorApp/JSONEditorApp";
import {
  getRemoteConfigChatBot,
  selectRemoteChatBot,
} from "../features/bots/botSlice";
import "./DrawerContent.css";

export default function BaseScenario() {
  const dispatch = useDispatch();
  const remoteChatBot = useSelector(selectRemoteChatBot);

  useEffect(() => {
    dispatch(getRemoteConfigChatBot());
  }, [dispatch]);

  return (
    <div>
      {remoteChatBot.baseScenario && (
        <div className="drawerContent">
          <JSONEditorApp
            json={JSON.parse(remoteChatBot.baseScenario)}
            data={"baseScenario"}
            base={true}
          />
        </div>
      )}
    </div>
  );
}
