import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useDispatch } from 'react-redux';
import { clearAuthenticationTokens } from '../../features/localstorage/localStorageSlice';
import {
  successfulLogin,
  signOut,
  failedLogin,
} from '../../features/authentication/authenticationSlice';
import { routeHistory } from '../../routing/history';
import { Menu, MenuItem } from '@material-ui/core';
import AppDrawerMenu from './AppDrawerMenuList';
import { Route } from 'react-router-dom';
import PrivateRoute from '../../routing/PrivateRoute';
import firebase from 'firebase/app';
import 'firebase/auth';
import { signOutAsync } from '../../features/authentication/authenticationSlice';
import { UserDetail } from '../../types/UserDetail';
import BaseScenario from '../../pages/BaseScenario';
import HomePage from '../../pages/HomePage/HomePage';
import BotManagerPage from '../../pages/BotManagerPage';
import SpecificBot from '../../pages/SpecificBot';
import SpecificHistory from '../../pages/SpecificHistory';
import EditsHistory from '../../pages/EditsHistory';
import SpecificGroup from '../../pages/SpecificGroup';
import BaseGroupScenario from '../../pages/BaseGroupScenario';
import BaseHistory from '../../pages/BaseHistory';
import SpecificBaseHistory from '../../pages/SpecificBaseHistory';
import CommandDM from '../../pages/CommandDM';
import CommandGroup from '../../pages/CommandGroup';
import LanguageKeys from '../../pages/LanguagesKeys';
import InactiveDiff from '../../pages/InactiveDiff';
import SpecificLanguage from '../../pages/SpecificLanguage';
import { initializeRemoteConfig } from '../../features/remoteconfig/remoteConfigSlice';

const drawerWidth = 240;
let useStyles;

const env = process.env.REACT_APP_STAGE === 'production' ? 'prod' : 'dev';

const color = env === 'prod' ? '#2196f3' : '#59AD23';

useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: color, // blue
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    authButton: {
      marginRight: theme.spacing(2),
      flex: 1,
    },
    title: {
      flexGrow: 1,
    },
    contentBoard: {
      flexGrow: 1,
    },
  }),
);

export default function AppMain() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      localStorage.setItem('user-last-path', window.location.pathname);
    }
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl);

  const onSignOutClick = () => {
    dispatch(signOutAsync());
    dispatch(clearAuthenticationTokens());
    dispatch(signOut());
    routeHistory.push('/login');
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const currentUser = {
        email: user.email,
        id: user.uid,
        token: localStorage.getItem('tumla-token'),
      } as UserDetail;

      dispatch(successfulLogin(currentUser));

      // Load remote config
      dispatch(initializeRemoteConfig());
    } else {
      dispatch(failedLogin());
      console.log('no user logged in');
    }
  });

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.drawerButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Tumla
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className={classes.authButton}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open2}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={onSignOutClick}>Sign Out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <AppDrawerMenu />
        <Divider />
      </Drawer>
      <main className={classes.contentBoard}>
        <PrivateRoute exact path="/bot-manager" component={BotManagerPage} />
        <PrivateRoute
          exact
          path="/bot-manager/:botId"
          component={SpecificBot}
        />
        <PrivateRoute
          exact
          path="/bot-manager/:botId/edits-history"
          component={EditsHistory}
        />
        <PrivateRoute
          exact
          path="/bot-manager/:botId/group-json/edits-history"
          component={EditsHistory}
        />
        <PrivateRoute
          exact
          path="/bot-manager/:botId/language/edits-history"
          component={EditsHistory}
        />
        <PrivateRoute
          exact
          path="/bot-manager/:botId/edits-history/:editId/pre=:previousId"
          component={SpecificHistory}
        />
        <PrivateRoute
          exact
          path="/bot-manager/:botId/group-json/edits-history/:editId/pre=:previousId"
          component={SpecificHistory}
        />
        <PrivateRoute
          exact
          path="/bot-manager/:botId/language/edits-history/:editId/pre=:previousId"
          component={SpecificHistory}
        />
        <PrivateRoute
          exact
          path="/bot-manager/:botId/group-json"
          component={SpecificGroup}
        />
        <PrivateRoute
          exact
          path="/bot-manager/:botId/language"
          component={SpecificLanguage}
        />
        <PrivateRoute exact path="/base-scenario" component={BaseScenario} />
        <PrivateRoute
          exact
          path="/base-scenario/group"
          component={BaseGroupScenario}
        />
        <PrivateRoute
          exact
          path="/base-scenario/command-dm"
          component={CommandDM}
        />
        <PrivateRoute
          exact
          path="/base-scenario/command-group"
          component={CommandGroup}
        />
        <PrivateRoute
          exact
          path="/base-scenario/language-keys"
          component={LanguageKeys}
        />
        <PrivateRoute
          exact
          path="/remote-config/inactive-diff/:field"
          component={InactiveDiff}
        />
        <PrivateRoute exact path="/base-history" component={BaseHistory} />
        <PrivateRoute
          exact
          path="/base-history/:editId/pre=:previousId"
          component={SpecificBaseHistory}
        />
        <Route exact path={`/`} component={HomePage} />
      </main>
    </div>
  );
}
