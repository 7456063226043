import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  FormControlLabel,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Switch, TextField } from "formik-material-ui";
import { clearBotData, getSpecificBotData } from "../../features/bots/botSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import "./Dialog.css";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../..";

toast.configure();

export default function MuiDialog({ open, setDialogOpen, data }) {
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  let { botId } = useParams<{ botId: string }>();

  const booleansSettings = Object.entries(data.defaultSettings).filter(
    ([key, value]) => {
      return typeof value === "boolean";
    }
  );

  function comparator(a, b) {
    if (a[0] < b[0]) return -1;
    if (a[0] > b[0]) return 1;
    return 0;
  }

  const sortedBooleanSettings = booleansSettings.sort(comparator);

  return (
    <Dialog
      open={open}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">@{data.name}'s settings</DialogTitle>
      <DialogContent>
        <div id="alert-dialog-description">
          <Formik
            initialValues={data.defaultSettings}
            onSubmit={async (values) => {
              setDisabled(true);
              
              let newValues = {
                id: data.id,
                name: data.name,
                scenario: data.scenario,
                title: data.title,
                token: data.token,
                defaultSettings: values,
                description: values.description,
              };
              delete newValues.defaultSettings.description;

              axiosInstance.post('upsertChatBot', newValues)
                .then(function (result) {
                  toast.success("🚀 DATA SENT!");
                  setDialogOpen(false);
                  dispatch(clearBotData());
                  dispatch(getSpecificBotData(botId));
                  console.log("DATA SENT!", result.data);
                })
                .catch(function (error) {
                  var code = error.code;
                  var message = error.message;
                  var details = error.details;
                  toast.error("🙄 SORRY, THERE WAS A PROBLEM!");
                  setDialogOpen(false);
                  console.error(
                    "SORRY, THERE WAS A PROBLEM!",
                    code,
                    message,
                    details
                  );
                });
            }}
          >
            {({ submitForm, isValid, dirty }) => (
              <Form>
                <Typography color="primary">Default Settings</Typography>
                <Box margin={1} display="grid">
                  <Field
                    component={TextField}
                    name="kickDelay"
                    type="number"
                    label="kickDelay"
                    style={{ margin: "3px", flexDirection: "row" }}
                  />
                  <Field
                    component={TextField}
                    select
                    name="kickDelayUnit"
                    type="text"
                    label="kickDelayUnit"
                    style={{ margin: "3px", flexDirection: "row" }}
                  >
                    <MenuItem value="minute">minute</MenuItem>
                    <MenuItem value="hour">hour</MenuItem>
                    <MenuItem value="">blank</MenuItem>
                  </Field>
                  {sortedBooleanSettings.map((setting) => (
                    <div key={setting[0]}>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            type="checkbox"
                            name={setting[0]}
                          />
                        }
                        label={setting[0]}
                      />
                    </div>
                  ))}
                </Box>
                <Box margin={1} display="grid">
                  <Typography color="primary">Description</Typography>
                  <Field component={TextField} name="description" type="text" />
                </Box>
                <Box margin={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!dirty || !isValid || disabled}
                    onClick={submitForm}
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}
